import axiosIns from "@/libs/axios";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // get all verified client accounts
    getAllAccounts(ctx) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`refreshPortfolioForClientAccountsVerified`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    getAccountCostCenter(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getAccountCostCenter`, data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    // Arange TC users
    fetchArangeFxExecutedTCUsers(ctx, user) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getArangeFxExecutedTCUsers`, user)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    //Fx spot forward swap Order Taken Given Through
    fetchArrangeFxExecutedOrderTakenGivenThrough(ctx, user) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getArrangeFxExecutedOrderTakenGivenThrough`, user)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    //Fx spot forward swap Currencies
    fetchArrangeFxExecutedCurrencies(ctx, user) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getArrangeFxExecutedCurrencies`, user)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    //search FXSfs
    searchFxExecuted(ctx, searchData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`searchFxExecuted`, searchData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    saveSearch(ctx, searchData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`saveSearch`, searchData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getLastSearch(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getLastSearch`, data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getSavedSearches(ctx, searchData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getSavedSearches`, searchData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    deleteSavedSearch(ctx, searchData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`deleteSavedSearch`, searchData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    deleteSavedDefault(ctx, searchData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`deleteSavedDefault`, searchData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    //Last Search Fxsfs
    getLastSearchFxSpotForwardSwap(ctx, searchData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getLastSearchFxSpotForwardSwap`, searchData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    //filter Trade Date
    fetchFilterTradeDate(ctx, date) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getFilterFXTradeDate`, date)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    //filter settle Date
    fetchFilterSettlementDate(ctx, date) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getFilterFXSettleDate`, date)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    //create trade data
    createNewFXEntry(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`createNewFXEntry`, data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    //checkTCUtiJournalCreated
    checkTCUtiJournalCreated(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`checkTCUtiJournalCreated`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    //amend trade
    getAmendSelectedFXTrade(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getAmendSelectedFXTrade`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    //check trade verify
    checkTradeVerifyOperation(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`checkTradeVerifyOperation`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    //check fx trade user opr.
    checkFxTradeUserOperation(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`checkFxTradeUserOperation`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    //check fx update Amended
    checkFXUpdateAmendment(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`checkFXUpdateAmendment`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    //unverified trade operations
    removeTradeVerifyOperation(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`removeTradeVerifyOperation`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    //fx Trade Execution Update
    fxTradeExecutionUpdate(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`fxTradeExecutionUpdate`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    //fx Trade Execution Update OCO
    fxTradeExecutionUpdateOCO(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`fxTradeExecutionUpdateOCO`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    //get trade verify opr.
    getVerifySelectedFxTrade(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getVerifySelectedFxTrade`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    //trade FX verify opr.
    tradeFxVerifyOperation(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`tradeFxVerifyOperation`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    //trade FX view opr.
    getViewSelectedFxTrade(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getViewSelectedFxTrade`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    //trade FX copy opr.
    getCopySelectedFxTrade(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getCopySelectedFxTrade`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    copySelectedFxTrade(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`copySelectedFxTrade`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    getCanceledFxExecuteById(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getCanceledFxExecuteById`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    /// journal section

    getCreateJournalFxTrade(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getCreateJournalFxTrade`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    checkJournalTradeVerifyOperation(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`checkJournalTradeVerifyOperation`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    checkJournalOperations(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`checkJournalOperations`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    checkFxJournalOperations(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`checkFxJournalOperations`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    createFxJournalEntry(ctx, journal) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`createFxJournalEntry`, journal)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getAmendFxDetailDatas(ctx, id) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getAmendFxDetailDatas`, id)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    getViewAmendedFxSelectedTrade(ctx, id) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getViewAmendedFxSelectedTrade`, id)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getViewFxJournalTrade(ctx, id) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getViewFxJournalTrade`, id)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    //reversal section

    checkTCUtiReserved(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`checkTCUtiReserved`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    checkReversalInitiated(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`checkReversalInitiated`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getAmendFxInitialise(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getAmendFxInitialise`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    fxTradeInitiateReversal(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`fxTradeInitiateReversal`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    getFxReverseEntry(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getFxReverseEntry`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    reverseFxEntry(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`reverseFxEntry`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    getFxUndoInitiateReversal(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getFxUndoInitiateReversal`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    fxUndoInitiateReversal(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`fxUndoInitiateReversal`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    //get holidays
    getHolidays(ctx, user) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getHolidays`, user)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    //send email
    sendInitiateReversalMail(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`sendInitiateReversalMail`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    //get Last 7 Days Fx Trades
    getLast7DaysFxTrades(ctx) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`getLast7DaysFxTrades`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    //trade Multiple Change FX Status Opr.
    tradeMultipleChangeFxStatusOpr(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`tradeMultipleChangeFxStatusOpr`, data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    //trade Journal with multiple existing
    tradeJournalFXWithExisting(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`tradeJournalFXWithExisting`, data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    //trade Multiple Select Transfer Copy Opr
    createExistingFXJournalEntry(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`createExistingFXJournalEntry`, data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    //trade Multiple Select Fx Copy Opr.
    tradeMultipleSelectFxCopyOpr(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`tradeMultipleSelectFxCopyOpr`, data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    //save Default Fx Entry.
    saveDefaultEntry(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`saveDefaultEntry`, data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    //get Saved Default Entries
    getSavedDefaultEntries(ctx, filter) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getSavedDefaultEntries`, filter)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    exportXlsx(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("user", { params: queryParams })
          .post("exportFxXlsx", data, { responseType: "blob" })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    exportCsv(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("user", { params: queryParams })
          .post("exportFxCsv", data, { responseType: "blob" })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },
  },
};
